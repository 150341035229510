import React, { useEffect } from 'react'
import { FiTrash2 } from "react-icons/fi";
import { AiOutlineSearch, AiTwotoneEdit } from "react-icons/ai"
import { FiEdit } from "react-icons/fi"
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteProjectWithId } from "../../Actions/TasksAction";
import SweetAlertTwo from "../SweetAlertTwo";
import Swal from "sweetalert2";
import { MdFileCopy, MdOutlineDownloadForOffline } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import jsPDF from 'jspdf';
import { allProjects } from '../../Actions/TasksAction';
import { useSelector } from "react-redux"
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { appName } from '../../Global/Global';
const ProjectRows = ({ data, searchData, setId, setSearchPage, searchPage, setTotalDataLength, setIsClicked }) => {

    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })
    const dispatch = useDispatch();
    const selector = useSelector(state => state.auth);

    const deleteProject = (id) => {
        setInfo({
            id: id,
            enable: true
        })
        setIsClicked(1);
    }
    const onConfirmData = (Swal) => {
        dispatch(deleteProjectWithId(info.id, searchPage, setSearchPage, searchData, Swal))
        setInfo({
            id: -1,
            enable: false
        })
        setId(info.id);
    }

    const handleCancle = () => {
        setInfo({
            id: -1,
            enable: false
        })
    }
    const CopyData = () => {
        navigator.clipboard.writeText(data.orginalContent)
    }
    const CreateDocFile = () => {

        let documentName = data.campaignName;
        let fileDownload = document.createElement("a");
        let copyText = data.orginalContent
        fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(copyText)
        fileDownload.download = `${documentName}.doc`;
        fileDownload.click();
    }


    const formatText = (text) => {
        const regex = /(.{100})/g;
        return text.replace(regex, '$1<br>');
    };
    const printHandle = () => {
        let copyText = data.orginalContent
        const element = document.createElement('div');
        element.innerHTML = `<pre>${formatText(copyText)}`;
        element.style.fontFamily = 'Arial';
        element.style.fontSize = '14px';
        element.style.color = 'black';
        element.style.padding = '10px';
        element.style.whiteSpace = 'pre-wrap';
        const options = {
            margin: 10,
            filename: `${appName}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        html2pdf().set(options).from(element).save();
    }
    return (
        <tr className='text-light'>
            <td>
                <div className="proBox">
                    <div className="project-category-icon"><FiEdit /></div>
                </div>
            </td>
            <td >
                <strong>
                    {data.campaignName}
                </strong>
                <br />
                <span className="payMode">
                    {data.task}
                </span>

            </td>
            <td>{data.created}</td>
            <td>

                <div className="d-flex Project_action">

                    {selector.user.isClient === "0" ?
                        (<>
                            <Link to={`/response-page?tId=${data.taskId}&id=${data.id}`}
                                className="roundBox"
                                style={{ position: 'relative' }}
                                title="Edit"
                            >

                                <AiTwotoneEdit
                                    size={20}
                                    className="align-icons"
                                />
                            </Link>
                            <span
                                className="roundBox"
                                style={{ position: 'relative', cursor: 'pointer', color: 'red' }}
                                title="Delete"
                                onClick={() => { deleteProject(data.id) }}
                            >
                                <FiTrash2 size={20} className="align-icons" />
                            </span>
                        </>
                        ) : ''
                    }
                    <span>
                        <button className="btn pdf_dots dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <BsThreeDots style={{ color: "#fff", fontSize: "28px" }} />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <span
                                onClick={CopyData}
                                className="dropdown-item d-flex  cursor-pointer"

                                style={{ fontSize: "16px", color: "#000", fontFamily: "arial", fontWeight: "500", }}
                            >
                                <MdFileCopy className='mr-2' size={20} />
                                COPY

                            </span>

                            <span
                                onClick={CreateDocFile}
                                className="dropdown-item cursor-pointer"
                                style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                            >
                                <MdOutlineDownloadForOffline className='mr-1' size={20} />
                                DOC
                            </span>

                            <span

                                onClick={printHandle}
                                className="dropdown-item cursor-pointer"
                                style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                            >
                                <MdOutlineDownloadForOffline className='mr-1' size={20} />
                                PDF
                            </span>


                        </ul>
                    </span>


                </div>


            </td>
            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="Do you want to delete this task."
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Delete'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirmData}
                onCancel={handleCancle}
            />
        </tr>
    )
}

export default ProjectRows;