import React from "react";
import ReelAppsLogo from '../images/ReelApps.png';
import { useSelector } from "react-redux";
import { appName } from "../Global/Global";
import { loginFooter } from "../Global/Global";
const Footer = () => {
    let termsURL = `https://backend${loginFooter}/assets/terms.html`
    let privacyURL = `https://backend${loginFooter}/assets/privacy.html`
    const rebrandData = useSelector(state => state.rebrand);

    return (
        <>
            <footer className="appFooter innerPage">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="footer-single">
                                <ul className="footer-list-left">
                                    <li><a href={termsURL} target="_blank" rel="noreferrer">TERMS |</a> </li>
                                    <li><a href={privacyURL} target="_blank" rel="noreferrer">PRIVACY </a> </li>
                                    {/* <li><a href="http://support.vineasx.com/" target="_blank" rel="noreferrer">SUPPORT </a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="footer-single">
                                <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                                <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="#" rel="noreferrer">{appName}</a> Developed and Maintained by <a href="#" rel="noreferrer">{appName}</a></p>

                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-single reelLogo">
                                {/* <a href="https://reelapps.io/" target="_blank" rel="noreferrer"><img src={ReelAppsLogo} alt={'logo'} /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default React.memo(Footer);